/*
 * "DEFAULT / HOME" PAGE
 * –––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––– */

$mq-xxs: 321px  !default;
$mq-xs: 376px   !default;
$mq-sm: 769px   !default;
$mq-lg: 1025px  !default;


.section-default {

}

@media only screen and (min-width: $mq-xs) {
    // medium viewport: sidebar drops to two columns beneath main text
    .section-default {
        .site-content__sidebar {
            display: flex;
            justify-content: space-between;
        }

        .c-sidebar__block {
            width: calc(50% - 1rem);
            margin-top: 3rem;
        }
    }
}

@media only screen and (min-width: $mq-sm) {
    // regular sidebar and main column
    .section-default {
        .site-content__sidebar {
            display: block;
        }
        .c-sidebar__block {
            width: auto;
        }
        .c-sidebar__block:first-child {
            margin-top: 0rem;
        }
    }
}

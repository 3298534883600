/*
 * COMPONENT : INFOBLOCK
 * –––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––– */

$mq-xxs: 321px  !default;
$mq-xs: 376px   !default;
$mq-sm: 769px   !default;
$mq-lg: 1025px  !default;

$infoblock_img_width: 180px !default;

.c-infoblock {
    display: flex;
    flex-direction: column;
    padding: 0;
    margin: 5rem 0 7rem;
    background-color: var(--bg-tint);

    &__img {
        min-width: $infoblock_img_width;

        & img[width][height] {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    &__body {
        font-family: var(--heading-font-family);
        padding: 1.25rem;

        h4 {
            font-weight: 500;
            margin: 0 0 0.75rem;
        }

        p {
            font-size: 16px;
            line-height: 1.5;
            letter-spacing: 0.15px;
            margin-bottom: 0;
        }
    }
}

@media only screen and (min-width: $mq-xs) {
    .c-infoblock__body {
        padding: 1.65rem 2rem;
    }
}

@media only screen and (min-width: $mq-sm) {
    .c-infoblock {
        flex-direction: row;
    }
}

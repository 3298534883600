/*
 * COMPONENT : CONTENT : TEXT
 * –––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––– */

body {
    font-family: var(--text-font-family);
    font-size: var(--base-font-size);
    line-height: var(--base-line-height);
    color: var(--base-text-color);
}

.site-content {

    &__wrapper {
//        margin-top: 4rem;
    }

    p, li, dd, dt {
        font-size: var(--text-font-size);
    }
    p, ul, ol, dd {
        margin-bottom: 1rem;
    }
    p + ul,
    p + ul {
        margin-top: -1rem;
    }

    .lead {
        font-size: var(--lead-font-size);
        margin-bottom: 1.5rem;
        color: var(--color-primary);

        strong {
            font-weight: 400;
        }
    }



}

/*
 * COMPONENT : SEARCH RESULTS
 * –––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––– */

.section-search {
    q {
        font-weight: 600;
    }
}
.c-search-result {

    border-bottom: 1px solid var(--mid-grey);
    padding-bottom: 1.5rem;

    &:first-of-type {
        border-top: 1px solid var(--mid-grey);
        margin-top: 1.5rem;

    }

    .cms-content &__title {
        margin-top: 1.5rem;
        margin-bottom: 0.5rem;
    }

    &__excerpt {

        strong {
            font-weight: 600;
            background-color: #fea;
        }
    }

    .cms-content &__location {
        font-family: var(--heading-font-family);
        font-size: 15px;
        text-decoration: none;
        color: var(--mid-grey);
        &:hover {
            color: var(--color-primary);
        }
    }

}

.paginator {

    a {
        display: inline-block;
        font-family: var(--heading-font-family);
        font-size: 15px;
        padding: 1.5rem;
        color: var(--mid-grey);
        text-decoration: none;
        &:hover {
            color: var(--block);
        }

        &:first-of-type {
            padding-left: 0;
        }

        &[rel=prev]::before {
            content:"← ";
            vertical-align: -1px;
        }
        &[rel=next]::after {
            content:" →";
            vertical-align: -1px;
        }
    }


}

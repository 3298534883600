/*
 * SITE : NAVIGATION
 * –––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––– */

$menu-h-padding: 1rem !default;
$menu-v-padding: 1.05rem !default;

.site-header {
    display: flex;
    flex-direction: column;

    &__bottom {
        @include full-width();
        background-color: var(--color-primary);
        font-family: var(--heading-font-family);
        color: var(--white);
    }
}

.site-nav {
    max-width: var(--col-max-width);
    width: 100%;
    margin: 0 auto;
    padding: 0 3rem; // Fallback for browsers that don't support clamp / min / max
    // Browsers that support clamp / min / max
    @supports (padding: 0 clamp(1rem, 5%, 3rem)) {
        padding: 0 var(--side-margin);
    }
    display: flex;

    &__menu,
    &__submenu {
        list-style-type: none;
        margin-left: 0;
        padding-left: 0;
    }

    &__menu {
        display: flex;
        margin: 0 0 0 -1*$menu-h-padding;

        li {
            display: block;
            position: relative;

            &:hover {
                > ul {
                    display: block;
                }
            }
        }

        a {
            display: block;
            color: var(--white);
            text-transform: uppercase;
            font-size: 18px;
            line-height: 1;
            letter-spacing: 0.65px;
            text-decoration: none;
            padding: $menu-v-padding $menu-h-padding;

            &:hover {
                background-color: var(--color-primary-dk);
            }
        }
        .has-submenu {
            display: inline-flex;
            &:after {
                  content: "";
                  border: 0.35rem solid transparent;
                  border-top-color: rgba(white, 0.45);
                  margin-left: -1.25em;
                  transform: translateY(1.5rem);
                  margin-right: 0.6rem;
                }
            & > a {
                padding-right: 1.75rem;
            }
        }
    }
    &__submenu {
        position: absolute;
        left: 0;
        top: 3.2rem;
        display: none;
        background-color: var(--color-primary);

        li {
//            white-space: nowrap;

            a {
                width: 16rem;
                line-height: 1.4;
                font-size: 16px;
                font-feature-settings: "ss03"; // normal &-sign
                letter-spacing: 0.25px;
                text-transform: capitalize;
            }
        }
    }

    &__toggle {
        display: none;
    }

    &__search {
        margin-left: auto;
        display: flex;
    }
}

.search {

    &__form {
        display: flex;
        align-items: center;

        &.active {
            .search__input {
            width: 9.25rem;
            font-size: 16px;
            padding: 1px 0 3px 8px;
        }
        }
    }
    &__toggle,
    &__input {
        background-color: inherit;
        border: none;
        color: var(--white);
        color: #fff;
        font-family: var(--heading-font-family);
    }
    &__toggle {
        appearance: none;
        order: 1;
        font-size: 0;
        width: 34px;
        height: 34px;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%23fff' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='icon icon-search'%3E%3Ccircle cx='11' cy='11' r='8'%3E%3C/circle%3E%3Cline x1='21' y1='21' x2='16.65' y2='16.65'%3E%3C/line%3E%3C/svg%3E");
        background-position: 0 13px;
        background-repeat: no-repeat;
        border: 0;
        cursor: pointer;
    }
    &__input {
        width: 0;
        -moz-appearance: none;
        -webkit-appearance: none;
        background-color: rgb(255,255,255,0.3);
        margin-right: 10px;
        padding: 0;
        transition: width .15s ease-in-out;
    }
}

.c-site-nav__toggle {
    position: fixed;
    top: 38px;
    right: 3rem; // Fallback for browsers that don't support clamp / min / max
    // Browsers that support clamp / min / max
    @supports (right: clamp(1rem, 5%, 3rem)) {
        right: var(--side-margin);
    }
    width: 30px;
    height: 20px;
    transform: translateY(-50%);
    cursor: pointer;
    z-index:10;
    border: none;
    background-color: transparent;

    span {
        position: absolute;
        left: 0;
        width: 30px;
        height: 2px;
        background-color: #000;
        transition:all .2s linear 0ms;
    }

    &:hover span {
        background-color:#666;
    }

    span.top-bar {
        top:0;
    }

    span.middle-bar {
        top:8px;
    }

    span.bottom-bar {
        top:16px;
    }

    .show-nav & span.top-bar {
        top: 8px;
        transform:rotate(45deg);
    }

    .show-nav & span.middle-bar {
        opacity:0;
    }

    .show-nav & span.bottom-bar {
        top: 8px;
        transform:rotate(-45deg);
    }
}

@media only screen and (min-width: $mq-sm) {
    .c-site-nav__toggle {
        display:none;
    }
}

// Placeholder styling
::placeholder {
    color: #fff;
    font-family: var(--heading-font-family);
}

/* Styles for popover mobile menu (conditional on body.show-nav)
   Further styles in component.site.nav                          */

@media only screen and (max-width: $mq-sm) {

    .site-nav {
        display: none;
    }

    .show-nav {
        .site-nav {
            display: block;
            &__menu {
                flex-direction: column;
                width: 100%;
                margin: 2rem 0 0 0;
            }
            &__search,
            &__submenu {
                display: none;
            }
            .has-submenu::after {
                content: none;
            }
            li:hover {
                > ul {
                    display: none;
                }
            }
            a {
                font-size: 1.25rem;
                &:hover {
                    background: transparent;
                }
            }
            .active a {
                background: transparent;
            }
        }
    }
}

@media only screen and (max-width: $mq-xxs) {
    .c-site-nav__toggle {
        &,
        & span {
            width: 24px;
        }
    }
}

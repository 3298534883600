/*
 * WEBFONTS
 * –––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––– */

// Lelo Regular / Medium
@font-face {
    font-family: Lelo;
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url("../fonts/Lelo-Regular.woff2") format("woff2"),
         url("../fonts/Lelo-Regular.woff") format("woff");
}

@font-face {
    font-family: Lelo;
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url("../fonts/Lelo-Medium.woff2") format("woff2"),
         url("../fonts/Lelo-Medium.woff") format("woff");
}

// Minion Pro Regular + It / Medium / Semibold
@font-face {
    font-family: Minion;
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src:url("../fonts/MinionPro-Regular-latin.woff2") format("woff2"),
        url("../fonts/MinionPro-Regular-latin.woff")  format("woff");
}

@font-face {
    font-family: Minion;
    font-style: italic;
    font-weight: 400;
    font-display: swap;
    src: url("../fonts/MinionPro-RegularItalic-latin.woff2") format("woff2"),
         url("../fonts/MinionPro-RegularItalic-latin.woff")  format("woff");
}

@font-face {
    font-family: Minion;
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url("../fonts/MinionPro-Medium-latin.woff2") format("woff2"),
         url("../fonts/MinionPro-Medium-latin.woff")  format("woff");
}

@font-face {
    font-family: Minion;
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url("../fonts/MinionPro-Semibold-latin.woff2") format("woff2"),
         url("../fonts/MinionPro-Semibold-latin.woff")  format("woff");
}

/*
 * "NEWS" PAGE
 * –––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––– */

.cms-content {
    .c-news-article {

        &--excerpt {
            border-top: 1px solid var(--mid-grey);
            padding: 1.5rem 0;
        }

        &__title {
            font-size: var(--heading-font-size);
            font-weight: 600;
            line-height: 1.3;
            margin-bottom: 0.75rem;
        }

        &__meta {
            color: var(--mid-grey);
            font-size: var(--meta-font-size);
            line-height: 1;
            margin: 1rem 0;

            .separator {
                font-size: 20px;
                font-weight: 600;
                margin: 0 .2rem;
            }
        }

        .readmore {
            font-family: var(--heading-font-family);
            font-size: 16px;
            text-decoration: none;
            color: var(--dark-grey);
            &::after {
                content: " →";
                font-size: 14px;
                vertical-align: -1px;
            }
            &:hover {
                color: var(--black);
            }
        }
    }
}

/*
 * "CONTACT" PAGE : MAP
 * –––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––– */

// map header
h3#maplink {
    margin-top: 0;
    margin-bottom: 1.5rem;
}
// link to map header in sidebar
.maplink {
    display: block;
    margin-top: -0.25rem;
    margin-bottom: 1rem;
    color: var(--mid-grey);
    font-family: var(--heading-font-family);
    font-size: 16px;
    text-decoration: none;

    &::before {
        content: "→ ";
        font-size: 16px;
        vertical-align: -2px;
    }

    &:hover {
        color: var(--dark-grey);
    }
}

.directions {
    ol {
        margin-top: 0.5rem;
        padding-left: 1.3rem;

        + h4 {
            margin-top: 2rem;
        }
    }
}

.cms-content .routeplanner {
    display: inline-block;
    padding: 1.5rem 1.5rem 1.5rem 0;
    color: var(--mid-grey);
    font-family: var(--heading-font-family);
    font-size: 16px;
    text-decoration: none;

    &:hover {
        color: var(--black);
    }
}

// custom class set up in mapbox
.mapboxgl-marker svg g > g + g {
    fill: var(--color-primary);
}

.map-location-popup {
    // mapbox popup
    .mapboxgl-popup-content {
        padding: 9px 18px 12px 16px;
        background-color: var(--color-primary);
        box-shadow: 0 1px 15px rgba(0, 0, 0, 0.25);
        color: var(--white);
        font-family: var(--heading-font-family);
        font-size: 15px;

        strong {
            font-weight: 500;
        }
    }

    .mapboxgl-popup-close-button {
        top: -2px;
        background-color: transparent;
        color: var(--white);
        font-size: 14px;
    }
    // mapbox popup tip colour
    &.mapboxgl-popup-anchor-top .mapboxgl-popup-tip,
    &.mapboxgl-popup-anchor-top-left .mapboxgl-popup-tip,
    &.mapboxgl-popup-anchor-top-right .mapboxgl-popup-tip {
        border-bottom-color: var(--color-primary);
    }

    &.mapboxgl-popup-anchor-bottom .mapboxgl-popup-tip,
    &.mapboxgl-popup-anchor-bottom-left .mapboxgl-popup-tip,
    &.mapboxgl-popup-anchor-bottom-right .mapboxgl-popup-tip {
        border-top-color: var(--color-primary);
    }

    &.mapboxgl-popup-anchor-left .mapboxgl-popup-tip {
        border-right-color: var(--color-primary);
    }

    &.mapboxgl-popup-anchor-right .mapboxgl-popup-tip {
        border-left-color: var(--color-primary);
    }
}

/* prevent map showing on mobile menu (no joy with z-index) */
.show-nav .map {
    visibility: hidden;
}

/*
 * "PRACTICE" PAGE
 * –––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––– */

$mq-xxs: 321px  !default;
$mq-xs: 376px   !default;
$mq-sm: 769px   !default;
$mq-lg: 1025px  !default;

.section-practice {

}

.c-practice-area {

    &s__wrapper {
        display: grid;
        grid-gap: 2rem;
        grid-template-columns: repeat(auto-fit, minmax(15.5rem, auto));
    }

    font-size: 18px;
    border-top: 1px solid var(--mid-grey);
    padding-top: 1rem;
    display: flex;
    flex-direction: column;

    &__title {
        font-weight: 600;
    }
    &__title, p {
        margin-bottom: 0.75rem;
    }

    a {
        color: var(--color-primary);
        text-decoration: none;
    }

    .readmore {
        font-family: var(--heading-font-family);
        font-size: 16px;
        margin-top: auto;
        color: var(--dark-grey);
        &::after {
            content: " →";
            font-size: 14px;
            vertical-align: -1px;
        }
        &:hover {
            color: var(--black);
        }
    }

}

@media only screen and (max-width: $mq-sm) {
    // no need to repeat sidebar helper on list page on mobile
    .section-practice.listing-page {
        .site-content__sidebar {
            display: none;
        }
    }
    .section-practice .c-sidebar__block:first-child {
        margin-top: 3rem;
    }
}

/*
 * ELEMENTS : CMS-CONTENT
 * –––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––– */

.page-title {
    font-family: var(--heading-font-family);
    font-feature-settings: "ss03";
    font-size: 27px;
    font-weight: 500;
    line-height: 1.3;
    color: var(--color-primary);
    margin-bottom: 1rem;

    + *,
    + * h3 {
        margin-top: 0;
    }
}

.cms-content {

    .page-title + *,
    .page-title + * h3 {
        margin-top: 0;
    }

    p, li, dt, dd {
        font-size: var(--text-font-size);
    }

    p, ol, ul, dl {
        margin-bottom: 1rem;
    }

    h2, h3, h4, h5, strong, b {
        font-weight: 600;
    }

    h4 + ul:not(.spaced),
    h4 + ol,
    p + ul:not(.spaced),
    p + ol {
        margin-top: -.75rem;
    }
    ul {
        margin-left: 0;
        padding-left: 0;
        list-style-type: none;
    }

    li {
        padding-left: 1.25rem;
        text-indent: -1.1rem;
        margin-bottom: 0.25rem;
        &::before {
            content: "–";
            width: 1.1rem;
            display: inline-block;
            padding-left: 1rem;
        }
    }
    ul.spaced li {
        margin-bottom: 1rem;
    }

    ul ul {
        margin-left: .4rem;
        margin-bottom: 0;
    }

    a {
        color: var(--color-primary);
        &:hover {
            color: var(--color-primary-lt);
        }
    }

    h3 {
        font-family: var(--heading-font-family);
        font-weight: 400;
        letter-spacing: 0.25px;
        text-transform: uppercase;
        margin-top: 2rem;
    }
    h4 {
        font-size: var(--text-font-size);
        font-weight: 600;
        margin-top: 2rem;
        margin-bottom: 0.25rem;
    }
}

/*
 * "CONTACT" PAGE : FORM
 * –––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––– */

.section-contact {
    .site-content__sidebar {
        flex: 0 0 var(--sidebar-width);
    }

    .notice {
        color: var(--dark-grey);
        font-style: italic;
    }
}

// custom class set up in mapbox
.comConnectForm {
    fieldset {
        margin: 1rem 0 0;
        padding: 0;
        border: none;
    }

    legend {
        @include sr-only();
    }

    label.comText,
    label.comEmail,
    label.comTextarea {
        display: inline-block;
        margin-top: 0.5rem;
        font-family: var(--heading-font-family);
        font-size: 16px;
        line-height: 2.5;
        cursor: pointer;
    }

    input.comText,
    input.comEmail,
    textarea.comTextarea {
        padding: 0.35rem 0.5rem;
        border-width: 1px;
        border-style: solid;
        border-color: var(--form-border-color);
        border-top-color: var(--form-border-color-dk);
        border-left-color: var(--form-border-color-dk);
        background: var(--bg-tint);
    }

    input.comText,
    input.comEmail,
    textarea.comTextarea {
        width: 100%;
    }

    input.comText,
    input.comEmail {
        max-width: 43ch;
    }

    input.comSubmit {
        display: block;
        margin-top: 1rem;
        padding: 0.6rem 1.5rem;
        border: none;
        background-color: var(--color-primary);
        color: var(--white);
        font-family: var(--heading-font-family);
        font-size: 18px;
        letter-spacing: 1px;
        line-height: 1;
        text-transform: uppercase;
        cursor: pointer;

        &:hover {
            background-color: var(--color-primary-dk);
        }
    }
}

/*
 * "RESOURCES" PAGE
 * –––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––– */

$mq-xxs: 321px  !default;
$mq-xs: 376px   !default;
$mq-sm: 769px   !default;
$mq-lg: 1025px  !default;

$spacing-vertical: 0.3rem;
$area-indent: 1rem;
$left-indent: .6rem;

.section-resources {

    .cms-content {

        h3, h4 {
            margin-bottom: 0.5rem;
        }
        h3 + ul,
        h4 + ul {
            margin-top: 0rem;
        }
        .group-subhead {
            font-feature-settings: "ss03";
        }

        .c-resources-group + .c-resources-group {
            margin-top: 3rem;
        }

        .totop-link {
            font-family: var(--heading-font-family);
            font-size: 14px;
            margin-top: 2rem;
            color: var(--mid-grey);
            text-decoration: none;
            &::before {
                content:"↑";
                margin-right: 0.35rem;
            }
            &:hover {
                color: var(--dark-grey);
            }
        }
    }

    .resource-category-list {
        list-style-type: none;
        padding: 0;
        margin: 0;

        li {
            padding-bottom: $spacing-vertical;
            font-size: var(--sidemenu-font-size);
            line-height: 1.5;

            a {
                display: block;
                color: var(--color-primary);
                text-decoration: none;
                padding-left: $area-indent;
                &:hover {
                    color: var(--black);
                }
            }

            &::before {
                content: "▶";
                font-size: .5rem; // 10px
                float:left;
                margin-top: 0.45rem;
                color: var(--color-primary);
            }
        }
    }
}
@media only screen and (max-width: $mq-sm) {
    // no need to repeat sidebar helper on mobile
    .section-resources {
        .site-content__sidebar {
            display: none;
        }

        .btn-edit-link {
            float: right;
        }
    }
}

/*
 * SITE : FOOTER
 * –––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––– */

$mq-xxs: 321px  !default;
$mq-xs: 376px   !default;
$mq-sm: 769px   !default;
$mq-lg: 1025px  !default;

.site-footer {
    margin-top: auto;

    &__prefooter {
        font-size: var(--small-font-size);
        line-height: var(--tight-line-height);
        color: var(--dark-grey);
        text-align: center;
        background-color: var(--bg-tint);
        padding: 1.5rem;
        margin: 3rem 0 2rem;

        p + p {
            margin-top: 0.75rem;
        }
    }

    &__contact {
        display: flex;
        flex-wrap: wrap;
        font-size: var(--footer-font-size);
        margin-bottom: 2rem;
//        justify-content: space-between;
        border-top: 1px solid var(--mid-grey);

        > * {
            width: var(--sidebar-width);
            margin: 0;
            padding-top: 1rem;
        }

        a {
            color: var(--color-text);
            text-decoration: none;
            border-bottom: 1px solid var(--mid-grey);
            &:hover {
                color: var(--mid-grey);

            }
        }

    }

    &__nav {

    }

}

.c-toplink {
    position: fixed;
    bottom: 24px;
    right: 24px;
    line-height: 1;
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' data-name='Layer 1' viewBox='0 0 24 24'%3E%3Cpath d='M17.71 11.29l-5-5a1 1 0 0 0-.33-.21 1 1 0 0 0-.76 0 1 1 0 0 0-.33.21l-5 5a1 1 0 0 0 1.42 1.42L11 9.41V17a1 1 0 0 0 2 0V9.41l3.29 3.3a1 1 0 0 0 1.42 0 1 1 0 0 0 0-1.42z'/%3E%3C/svg%3E");
    width: 24px;
    height: 24px;
    transition: opacity .3s,visibility .3s;
    visibility: hidden;
    opacity: 0;

    &.is-visible {
        visibility: visible;
        opacity: 1;
    }

    .show-nav &{
        display: none;
    }
}

@media only screen and (max-width: $mq-sm) {
    .site-footer {
        &__copyright {
            width: 100%;

            br {
                display: none;
            }

            a {
                white-space: pre;
            }
        }
    }
}
@media only screen and (min-width: $mq-sm) {
    .site-footer {
        &__contact {
            justify-content: space-between;
        }
    }
}

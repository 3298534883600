/*
 * COMPONENT : CONTENT : SIDEBAR
 * –––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––– */

$border-width: 1px;
$border-style: solid;
$spacing-vertical: 0.6rem;
$area-indent: 1rem;
$left-indent: .6rem;

.c-sidebar {
    &__block h3,
    &__head {
        font-family: var(--heading-font-family);
        font-weight: 400;
        text-transform: uppercase;
        letter-spacing: 0.25px;
        margin-bottom: 1rem;
    }

    &__block {
        & + & {
            margin-top: 3rem;
        }

        &:first-child {
            margin-top: 0.25rem;
        }

    &.areas {
        ul {
            list-style-type: none;
            padding: 0;
            margin: 0;
            border-bottom: $border-width $border-style var(--light-grey);

            li {
                margin-bottom: $spacing-vertical;
                padding-top: $spacing-vertical;
                font-size: var(--sidemenu-font-size);
                line-height: 1.4;
                border-top: $border-width $border-style var(--light-grey);

                a {
                    display: block;
                    color: var(--color-primary);
                    text-decoration: none;
                    padding-left: $area-indent;
                    &:hover {
                        color: var(--black);
                    }
                }

                &::before {
                    content: "▶";
                    font-size: .5rem; // 10px
                    float:left;
                    margin-top: 0.45rem;
                    color: var(--color-primary);
                }
            }
        }
    }



    &.list {
        ul {
            list-style-type: none;
            padding: 0;
            margin: 0;

            li {
                text-indent: -$left-indent;
                padding-left: $left-indent;

                &::before {
                    content: "–";
                    display: inline-flex;
                    margin-left: $left-indent;
                    color: inherit;
                }
            }
        }
    }

    &.bare {
        ul {
            list-style-type: none;
            padding: 0;
            margin: 0;

            li {
                margin-bottom: $spacing-vertical;
                font-size: var(--small-font-size);

                a {
                    display: block;
                    text-decoration: none;
                    color: var(--black-grey);

                    &:hover {
                        color: var(--color-primary);
                    }
                }
            }
        }
    }

    &.secondary {
        ul li a {
            color: var(--color-text-muted);
            &:hover {
                color: var(--black);
            }
        }
    }

}
}

/*
 * COMPONENT : LAWYER BADGES
 * –––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––– */

.c-profile__accolades {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: top;
    margin-top: 1rem;
}

.c-profile__badge {
    width: auto;
    height: 85px;
    margin-bottom: 2rem;

    * {
        text-decoration: none;
    }

    svg,
    img,
    img[width][height] {
        width: auto;
        height: 100%;
    }
}

/*
.sl-badge {
    transform: scale(0.61);
    transform-origin: top;
    width: 150px;
}
*/

/*
 * PAGE : LAYOUT
 * –––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––– */

$mq-xxs: 321px  !default;
$mq-xs: 415px   !default;
$mq-sm: 769px   !default;
$mq-lg: 1025px  !default;

html {
    scroll-behavior: smooth;
}
body {
    overflow-x: hidden;
    font: 100%/1.5 system-ui, sans-serif;
}

.container {
    margin: 0 auto;
    padding: 0 3rem; // Fallback for browsers that don't support clamp / min / max
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    max-width: var(--col-max-width);
    // Browsers that support clamp / min / max
    @supports (padding: 0 clamp(1rem, 5%, 3rem)) {
        padding: 0 var(--side-margin);
    }
}


.site-content {

    &__wrapper {
        display: flex;
        flex-direction: column;
    }
    &__body {
        flex-grow: 1;
    }

}

@media only screen and (min-width: $mq-sm) {
    .site-content {
        &__wrapper {
            flex-direction: row-reverse;
        }
        &__sidebar {
            min-width: var(--sidebar-width);
            margin-right: 3rem; // Fallback for browsers that don't support clamp / min / max
            // Browsers that support clamp / min / max
            @supports (margin-right: clamp(3rem, 7%, 5rem)) {
                margin-right: var(--column-gutter);
            }
        }
    }
}

.show-on-desktop {
    display: none;
}
.show-on-mobile {
    display: block;
}

@media only screen and (min-width: $mq-sm) {
    .show-on-desktop {
        display: block;
    }
    .show-on-mobile {
        display: none;
    }
}

/* Helper class for full-width content */
@mixin full-width() {
    width: 100vw;
    position: relative;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
}
.full-width {
    @include full-width();
}

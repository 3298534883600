/*
 * "SEARCH" PAGE
 * –––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––– */

.section-search {

    .site-content {

        &__body {
            p {
                font-size: var(--text-font-size);
            }
        }
    }

}

/*
 * "PROFILE" PAGE
 * –––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––– */

.c-profile {

    &__meta {
        margin: 1.125rem 0 1rem 0;
        font-size: var(--sidemenu-font-size);
    }
    &__meta,
    &__meta a {
        color: var(--color-primary);
        text-decoration: none;
        &:hover {
            color: var(--black);
        }
    }

    h3 {
        margin-top: 2.5rem;
    }

    .attorney-position {
        margin: -1rem 0 0 0;
        font-weight: 400;
        font-family: var(--heading-font-family);
        font-size: 21px;
    }

    &__img {
        margin-bottom: 2.5rem;
        max-width: 450px;
        margin-left: auto;
        margin-right: auto;
    }

    &__cv {
        ul + h3 {
            margin-top: 2.5rem;
        }
        ul {
            font-size: var(--meta-font-size);
            padding-left: 0;
            margin-left: 0;
            list-style-type: none;
//            color: var(--color-text-muted);
        }
        li {
            margin-bottom: 0.35rem;
        }
    }

    &__news {
        ul {
            font-size: var(--base-font-size);
        }

        .readmore {
            font-family: var(--heading-font-family);
            font-size: 16px;
            margin-top: 1rem;
            color: var(--color-primary);
            text-decoration: none;
            color: var(--dark-grey);

            &::after {
                content: " →";
                font-size: 14px;
                vertical-align: -1px;
            }

            &:hover {
                color: var(--black);
            }
        }
    }
}

@media only screen and (min-width: $mq-sm) {
    .c-profile {
        &__cv {
            ul {
                color: var(--color-text-muted);
            }
        }
    }
}

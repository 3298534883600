/*
 * SITE : UTILITY NAVIGATION
 * –––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––– */

$mq-xxs: 321px  !default;
$mq-xs: 376px   !default;
$mq-sm: 769px   !default;
$mq-lg: 1025px  !default;

$utility-nav-height: 5rem !default;

.btn-edit-link {
    margin-left: 1em;
    background-color: #fea;
    color: #000;
    text-decoration: none;
    padding: .25rem .5rem;
    font-family: var(--heading-font-family);
    font-size: 13px;
    text-transform: uppercase;
    letter-spacing: 1px;
    line-height: 1;
    vertical-align: 2px;
    &:hover {
        background-color: #fd4;
    }
}

.page-utility-nav {
    height: $utility-nav-height;
    display: flex;
    align-items: center;

    > * + * {
        margin-left: 2rem;
    }

    .subnav__item {
        font-family: var(--heading-font-family);
        font-size: 16px;
        text-decoration: none;
        color: var(--dark-grey);
        &:hover {
            color: var(--black);
        }
    }

    .backlink::before {
        content: "← ";
        font-size: 15px;
        vertical-align: -1px;
    }

    .btn-edit-link {
        margin-left: auto;
        padding: 0.4rem 0.75rem;
        vertical-align: 0;
    }
}

@media only screen and (min-width: $mq-sm) {
    .page-utility-nav {
        margin-left: calc(var(--sidebar-width) + var(--column-gutter));
    }
}

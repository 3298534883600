/*
 * DESIGN TOKENS
 * –––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––––– */

$mq-xxs: 321px  ;
$mq-xs: 415px   ;
$mq-sm: 820px   ;
$mq-lg: 1025px  ;

:root {

    // Metrics
    --col-max-width: 72rem;
    --sidebar-width: 15rem;
    --column-gutter: clamp(4rem, 9%, 7rem);
    --side-margin: clamp(1rem, 6%, 3rem);

    // Borders
    --radius: 3px;

    // Typography
    --text-font-family: Minion, Georgia, "Times New Roman", Times, serif;
    --heading-font-family: Lelo, Verdana, Tahoma, sans-serif;
    --base-font-size: 16px;
    --lead-font-size: 20px;
    --small-font-size: 16px;
    --text-font-size: 17px;
    --heading-font-size: 18px;
    --footer-font-size: 18px;
    --address-font-size: 18px;
    --meta-font-size: 16px;
    --sidemenu-font-size: 18px;
    --base-line-height: 1.4;
    --tight-line-height: 1.2;
    --loose-line-height: 1.7;

    // Colors
    --blank: #000;
    --white: #fff;
    --black-grey: #444;
    --dark-grey: #777;
    --mid-grey: hsl(60, 5%, 55%);
    --light-grey: #ccc;
    --color-primary: #07508e; // blue
    --color-primary-dk: #063761; // dk-blue rollover
    --color-text: #000;
    --color-text-muted: hsl(60, 4%, 58%);
    --bg-primary: #fff;
    --bg-page: #efefef;
    --bg-tint: hsl(60, 10%, 94%);
    --form-border-color: hsl(60, 10%, 80%);
    --form-border-color-dk: hsl(60, 10%, 65%);

}

@media only screen and (min-width: $mq-xxs) {
    :root {
        --text-font-size: 18px;
    }
}

@media only screen and (min-width: $mq-xs) {
    :root {
        --text-font-size: 20px;
        --meta-font-size: 18px;
        --lead-font-size: 23px;
        --heading-font-size: 22px;
        --sidemenu-font-size: 19px;
    }
}
